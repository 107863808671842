<script>
export default {
  data() {
    return {
      LangID: "",
    };
  },
  mounted() {
    var self_ = this;
    self.LangID = this.$route.params.lang;
    self_.lang(self.LangID);
  },
  methods: {
    lang(lang) {
      localStorage.setItem("lang", lang);
      localStorage.setItem("Changelang", "true");
      this.$router.push('/')
    },
  },
};
</script>
